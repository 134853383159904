.modal-event .modal-content {
  background-color: #424242;
}

.modal-event .modal-dialog {
  min-width: 550px;
}

.container-content h4 {
  color: rgba(255, 255, 255, 0.7);
}

.container-content p {
  color: #ffff;
}

.buttonClose :hover {
  color: #ffff;
  cursor: pointer;
}

.edit-button :hover {
  color: #ffff;
  cursor: pointer;
}

.delete-button :hover {
  color: #ffff;
  cursor: pointer;
}
