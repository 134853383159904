@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../../_metronic/_assets//fonts/Helvetica-neue/HelveticaNeue-Roman.otf")
    format("opentype");
}

.container-info-detail {
  margin-top: 5px;
  background-color: white;
}

.btn-cancel {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-left: 3px;
}

.btn-cancel-detail {
  background-color: #fff;
  border-color: #ccc;
  color: #333;
  width: 90px;
  height: 36px;
}

.btn-cancel-detail:hover {
  background-color: gray;
}

.container-content-detail {
  padding: 0 10vw;
  margin: 3vh 0;
}

.container-content-detail .card-detail {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px 1vw;
  overflow: auto;
  background-color: white;
}

.icon-cloud {
  margin: auto;
  height: 31px;
  width: 33px;
  margin: 0 auto;
}

.title-detail {
  font-size: 20px;
  font-weight: 500;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #777777;
  display: flex;
  align-items: center;
}

.arrow-navigation-icon {
  width: 3rem !important;
  height: 3rem !important;
  margin-left: auto;
  margin-right: 0;
}

.content-detail {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  margin: 0;
}

.content-detail {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #333333;
  margin: 0;
}
