.event-cell {
  /* position: absolute; */
  /* top: 60px; */
  margin-top: 100;
}
.event-cell-custom {
  /* font-family: "Museo Sans 500";
  font-size: 18px;
  color: #666666; */
  /* position: absolute; */
  /* bottom: 20; */
  /* margin-top: 20; */
  /* top: 100; */
  /* padding-bottom: 2em; */
  /* margin-top: 30px; */
  /* background-color: red; */
}
.date-cell {
  /* background-color: orange; */
  /* position: relative; */
}