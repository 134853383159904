@font-face {
  font-family: "Museo Sans 500";
  src: url("//db.onlinewebfonts.com/t/16697a764b01cdcc691b05f001046bc3.woff")
    format("woff");
}

@font-face {
  font-family: "Museo Sans 700";
  src: url("../../../../_metronic/_assets/fonts/Museo-sans/MuseoSans_700.otf")
    format("opentype");
}

@font-face {
  font-family: "Museo Sans 900";
  src: url("../../../../_metronic/_assets/fonts/Museo-sans/MuseoSans_900.otf")
    format("opentype");
}

@font-face {
  font-family: "Museo Sans 300";
  src: url("../../../../_metronic/_assets/fonts/Museo-sans/MuseoSans-300.otf")
    format("opentype");
}

.donation-type {
  font-family: "Museo Sans 500";
}

.cash-contribution {
  font-family: "Museo Sans 500";
  font-size: 18px;
  color: #666666;
}

.buttonDetails p {
  font-family: "Museo Sans 300";
  font-size: 14px;
  font-weight: bold;
}

.button-submit span {
  font-family: Museo Sans 900;
  font-size: 18px;
}
.button-donate-btm span {
  font-family: "Museo Sans 500";
  font-size: 15px;
  font-weight: 700;
}

/* Mobile Size */
@media screen and (max-width: 376px) {
  .buttonDetails {
    width: 80px;
    height: 30px;
  }
  .buttonDetails p {
    font-size: 10px;
    font-weight: bold;
  }
  .message-checkbox {
    font-size: 10px;
    color: #666666;
  }

  .cash-info p {
    text-align: center;
    font-size: x-small;
  }
}

/* Desktop Size  */
@media screen and (min-width: 480px) {
  .container-form-detail {
    padding: 50px 0px;
    margin: 0 auto;
    width: 85%;
    max-width: 1500px;
  }
  .container-form-detail h1 {
    font-family: "Museo Sans 500";
  }

  .message-checkbox {
    font-size: 16px;
    color: #666666;
  }
}

/* @media screen and (max-width: 420px) {
  .buttonDetails {
    width: 80px;
    height: 30px;
  }
  .buttonDetails p {
    font-size: 10px;
    font-weight: bold;
  }

  .message-checkbox {
    font-size: 10px;
  }
} */
