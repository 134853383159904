@font-face {
  font-family: "Museo Sans 500";
  src: url("//db.onlinewebfonts.com/t/16697a764b01cdcc691b05f001046bc3.woff")
    format("woff");
}

@font-face {
  font-family: "Museo Sans 700";
  src: url("../../../../_metronic/_assets/fonts/Museo-sans/MuseoSans_700.otf")
    format("opentype");
}

@font-face {
  font-family: "Museo Sans 900";
  src: url("../../../../_metronic/_assets/fonts/Museo-sans/MuseoSans_900.otf")
    format("opentype");
}

.donation-thanks {
  width: 85%;
  margin: 0 auto;
  margin-top: 35px;
  border: 2px solid #ddd;
}

.success-info {
  font-size: 18px;
  font-family: "Museo Sans 500";
  color: #666666;
  margin-top: 20px;
  margin-bottom: 20px;
}

.success-title {
  font-size: 40px;
  font-family: "Museo Sans 900";
  color: #1d1e23;
}

.happenings-info {
  border-bottom: 2px solid #fdd200;
  color: #1d1e23;
  margin-left: 5px;
  margin-right: 5px;
}
.happenings-info:hover {
  border-bottom: 2px solid #fdd200;
  color: #1d1e23;
}

.return-link {
  border-bottom: 2px solid #fdd200;
  color: #aaa;
  font-size: 16px;
  font-family: "Museo Sans 700";
}
.return-link:hover {
  border-bottom: none;
  color: #666;
  font-size: 16px;
  font-family: "Museo Sans 700";
}

.title-donation-thanks {
  margin-bottom: 15px;
}

/* // Large Size */
@media screen and (min-width: 480px) {
  .donation-thanks {
    margin-top: 10px;
  }
}

/* Mobile Size */
@media screen and (max-width: 480px) {
}
