@font-face {
  font-family: "Museo Sans 500";
  src: url("//db.onlinewebfonts.com/t/16697a764b01cdcc691b05f001046bc3.woff")
    format("woff");
}

@font-face {
  font-family: "Museo Sans 700";
  src: url("../../../../../_metronic/_assets/fonts/Museo-sans/MuseoSans_700.otf")
    format("opentype");
}

@font-face {
  font-family: "Museo Sans 900";
  src: url("../../../../../_metronic/_assets/fonts/Museo-sans/MuseoSans_900.otf")
    format("opentype");
}

@font-face {
  font-family: "Museo Sans 300";
  src: url("../../../../../_metronic/_assets/fonts/Museo-sans/MuseoSans-300.otf")
    format("opentype");
}

.content-footer li,
.address-footer,
.policy-footer {
  color: #898b94;
  list-style: none;
  font-size: 12px;
  font-family: "Museo Sans 500";
}

.li-bold {
  font-size: 14px;
  color: white;
  line-height: 2cm;
  margin-bottom: 8px;
  font-family: "Museo Sans 700";
}

.social-footer .icon-social {
  display: flex;
  justify-content: space-between;
  margin: 25px 0px;
  width: 150px;
}

.footer li {
  cursor: pointer;
}

.footer .donate-footer .li-bold a {
  color: #ffffff;
}

.footer .donate-footer .li-bold a:hover {
  color: #ffd200;
}

@media screen and (min-width: 480px) {
  .footer {
    min-height: 400px;
    height: 400px;
    width: 100%;
    background: #1d1e23;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 50px;
    margin-bottom: 0px;
    padding-top: 50px;
    padding-bottom: 0px;
  }
  .footer .content-footer {
    padding: 0px;
    display: flex;
    justify-content: space-between;
  }

  .content-footer .about-footer,
  .content-footer .our-work-footer,
  .content-footer .opportunities-footer {
    line-height: 30px;
  }

  .content-footer .opportunities-footer {
    padding-top: 15px;
    line-height: 30px;
  }
  .content-footer .donate-footer {
    padding-top: 15px;
    line-height: 10cm;
    margin-right: 60px;
  }

  .content-footer .social-footer {
    padding-top: 15px;
    line-height: 30px;
    margin-right: 50px;
  }
}

@media screen and (max-width: 420px) {
  .footer {
    min-height: 400px;
    height: 1100px;
    width: 500px;
    background: #1d1e23;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 50px;
    margin-bottom: 0px;
    padding-top: 25px;
    padding-bottom: 0px;
    padding-left: 10px;
  }

  .footer .content-footer {
    padding: 0px;
  }

  .content-footer li {
    margin-bottom: 5px;
  }

  .content-footer .about-footer,
  .content-footer .our-work-footer,
  .content-footer .opportunities-footer,
  .content-footer .donate-footer,
  .content-footer .social-footer {
    padding: 0px;
    margin: 0px 0px 35px 0px;
  }
  .content-footer .opportunities-footer ul,
  .content-footer .donate-footer ul,
  .content-footer .social-footer ul {
    padding: 0px;
    margin: 15px;
  }

  .address-footer,
  .policy-footer {
    line-height: 30px;
  }
}

.footer a {
  text-decoration: none;
  color: #898b94;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.footer a:hover {
  color: #ffff;
}

@media screen and (max-width: 376px) {
  .footer {
    min-height: 400px;
    height: 1100px;
    width: 100%;
    background: #1d1e23;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 50px;
    margin-bottom: 0px;
    padding-top: 25px;
    padding-bottom: 0px;
  }

  .footer .content-footer {
    padding: 0px;
  }

  .content-footer li {
    margin-bottom: 5px;
  }

  .content-footer .about-footer,
  .content-footer .our-work-footer,
  .content-footer .opportunities-footer,
  .content-footer .donate-footer,
  .content-footer .social-footer {
    padding: 0px;
    margin: 0px 0px 35px 0px;
  }
  .content-footer .opportunities-footer ul,
  .content-footer .donate-footer ul,
  .content-footer .social-footer ul {
    padding: 0px;
    margin: 15px;
  }

  .address-footer,
  .policy-footer {
    line-height: 30px;
  }
}

@media screen and (max-width: 361px) {
  .footer {
    min-height: 400px;
    height: 1100px;
    width: 100%;
    background: #1d1e23;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 50px;
    margin-bottom: 0px;
    padding-top: 25px;
    padding-bottom: 0px;
  }

  .footer .content-footer {
    padding: 0px;
  }

  .content-footer li {
    margin-bottom: 5px;
  }

  .content-footer .about-footer,
  .content-footer .our-work-footer,
  .content-footer .opportunities-footer,
  .content-footer .donate-footer,
  .content-footer .social-footer {
    padding: 0px;
    margin: 0px 0px 35px 0px;
  }
  .content-footer .opportunities-footer ul,
  .content-footer .donate-footer ul,
  .content-footer .social-footer ul {
    padding: 0px;
    margin: 15px;
  }

  .address-footer,
  .policy-footer {
    line-height: 30px;
  }
}
