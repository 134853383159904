@font-face {
  font-family: "Helvetica Neue";
  src: url("../../../../_metronic/_assets//fonts/Helvetica-neue/HelveticaNeue-Roman.otf")
    format("opentype");
}

.link-navbar {
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #777;
  font-size: 14px;
  margin: 0 10px;
}

.container-navbar {
  height: 100px;
  background-color: #f8f8f8;
  width: 97%;
  margin: 0 auto;
  border-bottom: 1.2px solid #e7e7e7;
}

.container-link {
  margin-left: 10px;
}
